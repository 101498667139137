/** @jsxImportSource @emotion/react */
import { Layout,Modal } from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Logo from './Logo';
import MenuContent from './MenuContent';
import { css } from '@emotion/react';
import { TEMPLATE, GRAY_SCALE, DARK_MODE } from "constants/ThemeConstant";
import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import utils from 'utils'
import { toggleCollapsedNav, onMobileNavToggle } from 'store/slices/themeSlice';
import UserImg from '../../assets/svg/user-square.svg'
import { useNavigate } from 'react-router-dom';
import SideNavWrapper from './SideNavWrapper.style';

const { Sider } = Layout;

export const SideNav = ({ navCollapsed, sideNavTheme, routeInfo, hideGroupTitle, currentTheme }) => {

  const props = { sideNavTheme, routeInfo, hideGroupTitle, navCollapsed }

  const mobileNav = useSelector(state => state.theme.mobileNav)
  const navType = useSelector(state => state.theme.navType)
  const headerNavColor = useSelector(state => state.theme.headerNavColor)
  const direction = useSelector(state => state.theme.direction)
  const navigate = useNavigate();
  const [user,setUser] = useState({});

  const { isMobile } = props;

  
  useEffect(()=>{
    const fullName = localStorage.getItem('fullName');
    const roleName = localStorage.getItem('roleName');

    setUser({
        name:fullName,
        role:roleName
    });
  },[])

  const dispatch = useDispatch()

  const isDarkTheme = currentTheme === 'dark'

  const navMode = useMemo(() => {
    if (!headerNavColor) {
      return utils.getColorContrast(isDarkTheme ? '#000000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor);
  }, [isDarkTheme, headerNavColor])

  const onToggle = () => {
    if (!isMobile) {
      dispatch(toggleCollapsedNav(!navCollapsed))
    } else {
      dispatch(onMobileNavToggle(!mobileNav))
    }
  }

  const clearAll = () =>{
    const adminEmail = localStorage.getItem('adminEmail');
    const userEmail = localStorage.getItem('userEmail');
    if (adminEmail || userEmail) {
      localStorage.removeItem('adminEmail');
      localStorage.removeItem('userEmail');
      navigate('/login?&userId=');
    }
    
  }

  return (
    <SideNavWrapper>
      <Sider
        css={css`
          height: calc(100vh - ${TEMPLATE.HEADER_HEIGHT}px); 
          // height: 100vh; 
          position: fixed !important;
          top: ${TEMPLATE.HEADER_HEIGHT}px;
          // box-shadow: 0 1px 4px -1px rgba(0,0,0,.15);
          box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px;
          z-index: 999;
          direction: ltr;
          ${currentTheme === 'light' && sideNavTheme !== SIDE_NAV_DARK ? `background-color: ${GRAY_SCALE.WHITE} !important;` : `background-color: ${TEMPLATE.SIDE_NAV_DARK_BG_COLOR} !important;`}
          ${currentTheme === 'dark' && sideNavTheme !== SIDE_NAV_DARK ? `background-color: ${DARK_MODE.BG_COLOR} !important` : ``}
          ${currentTheme === 'dark' && sideNavTheme === SIDE_NAV_DARK ? `background-color: ${TEMPLATE.SIDE_NAV_DARK_BG_COLOR} !important` : ``}
        `}
        className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''} ${navCollapsed ? 'sideNavCollapsed':''}`}
        width={SIDE_NAV_WIDTH}
        collapsed={navCollapsed}
      >
        {/* <div onClick={onToggle}>
          <Logo logoType={navMode} />
        </div> */}
        <Scrollbars autoHide>
          <MenuContent
            type={NAV_TYPE_SIDE}
            {...props}
          />
        </Scrollbars>
        {/* sidebar logout */}
        {/* <div className='userProfileDiv'>
          <img src={UserImg} alt="" height={40} width={40}/>
          <div className='userDetails'>
            <span>{user?.name}</span>
            <p>{user?.role}</p>
          </div>
          <button className='logOutBtn' onClick={() => {
              Modal.confirm({
                title: 'Confirm',
                content: 'Are you want you logout?',
                okText:'Yes, Logout',
                cancelText:'No',
                onOk:() =>{
                  clearAll()
                }
              });
            }}><i className='arrow_left_icon'></i></button>
        </div> */}
      </Sider>
    </SideNavWrapper>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme, currentTheme } = theme;
  return { navCollapsed, sideNavTheme, currentTheme }
};

export default connect(mapStateToProps)(SideNav);
