import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'
import { canAccess } from 'services/AuthorizedService'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard',
        path:'https://web.callhippo.com/',
        component: "",
    },
    ...(canAccess())?[{
        key: 'dashboard.analytics',
        path: `${APP_PREFIX_PATH}/analytics`,
        component: React.lazy(() => import('views/app-views/dashboards')),
    }]:[],
    {
        key: 'callanalytics.mycalls',
        path: `${APP_PREFIX_PATH}/callanalytics/mycalls`,
        component: React.lazy(() => import('views/app-views/callAnalytics/myCalls/index')),
    },
    ...(canAccess())? [{
        key: 'callanalytics.teamcalls',
        path: `${APP_PREFIX_PATH}/callanalytics/teamcalls`,
        component: React.lazy(() => import('views/app-views/callAnalytics/teamCalls/index')),
    }]:[],
    {
        key: 'dashboard.conversationcenter',
        path: `${APP_PREFIX_PATH}/call-conversation-center/:callsid`,
        component: React.lazy(() => import('views/app-views/conversationCenter')),
    },
    {
        key: 'dashboard.playlist',
        path: `${APP_PREFIX_PATH}/playlist`,
        component: React.lazy(() => import('views/app-views/Playlist')),
    },
    {
        key: 'dashboard.playlist.callanalytics',
        path: `${APP_PREFIX_PATH}/playlist/:playlistname`,
        component: React.lazy(() => import('views/app-views/Playlist/callAnalytics')),
    },
    ...(canAccess())?[{
        key: 'dashboard.playlist.custom.moment',
        path: `${APP_PREFIX_PATH}/custom-moment`,
        component: React.lazy(() => import('views/app-views/CustomTopics')),
    }]:[]
]