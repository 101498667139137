import styled from '@emotion/styled';
import dashboardIcon from '../../assets/svg/dashboardIcon_new.svg';
import chartIcon from '../../assets/svg/Chart.svg';
import myPlayListIcon from '../../assets/svg/playlist.svg';
import folderIcon from '../../assets/svg/folderIcon.svg';

const SideNavWrapper = styled.div`
.ant-layout-sider {
    /* box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.10) !important; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px !important;
    @media (max-width: 991.98px) {
        display:none !important;
    }
}
.sideBarMenuCtntr{
    font-size:13px !important;
    color: #464646 !important;
    .ant-menu-item{
        border-radius: unset !important;
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s !important;
        margin: 0 !important;
        &:active {
            background-color: rgba(0, 0, 0, 0.1) !important;
            color: #464646 !important;
        }
        &:hover{
            background-color: rgb(52, 73, 94) !important;
            color: #fff !important;
            .ant-menu-title-content {
                color: #fff !important;
                .dashboardIcon,.myPlayListIcon,.folderIcon{
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(281deg) brightness(103%) contrast(102%) !important;
                }
                span:nth-of-type(2){
                    color: #fff !important;
                }
            }
        }
        &::after {
            position: unset !important;
            display: none !important;  
        }
        .ant-menu-title-content {
            transition: unset !important;
            display: flex;
            align-items: center;
        }   
    }
    .myplaylist-menu-item,
    .back-to-webapp-menu-item,
    .callanalytics-menu-item{
        .ant-menu-title-content{
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    .callanalytics-menu-item{
        .ant-menu-sub{
            li{
               padding-left: 56px !important;
           }
        }
    }
    .dashboardIcon,
    .myPlayListIcon,
    .folderIcon,
    .chartIcon{
        height: 18px;
        width: 18px;
        min-width:18px !important;
        display: block;
        background-repeat: no-repeat;
        background-size: 18px!important;
        background-position: center;
    }
    .dashboardIcon {
        background-image: url(${dashboardIcon});
    } 
    .myPlayListIcon{
        transform:scale(1.2);
        background-image: url(${myPlayListIcon});
    }
    .folderIcon{
        background-image: url(${folderIcon});
    }
    .chartIcon {    
        background-image: url(${chartIcon});
        transform:scale(0.85);
    }
    .ant-menu-submenu{
        // border-radius:unset !important;
        .ant-menu-submenu-title{
            margin:0px !important;
            border-radius:unset !important;
            &:hover{
                background-color: rgb(52, 73, 94)!important;
                color: #fff!important;
                .chartIcon{
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(281deg) brightness(103%) contrast(102%) !important;
                }
            }
            &:active{
                background-color: #ffffff !important;
            }
        }
        .ant-menu-title-content{
            display: flex;
            align-items: center;
        }
    }
} 
.ant-menu-inline-collapsed{
    .ant-menu-item{
        padding: 0px 24px !important;
    }
    .ant-menu-submenu{
        padding: 0px 24px !important;
        border-radius:unset !important;
        &:hover{
            background-color: rgb(52, 73, 94)!important;
            color: #fff!important;
            .chartIcon{
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(281deg) brightness(103%) contrast(102%) !important;
            }
        }
        .ant-menu-submenu-title{
            padding: 0px !important;
        }
    }
}
`;

export default SideNavWrapper;