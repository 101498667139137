import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import { Layout, Grid, } from 'antd';
import navigationConfig from 'configs/NavigationConfig';
import { TEMPLATE, MEDIA_QUERIES } from 'constants/ThemeConstant';
import styled from '@emotion/styled';
import utils from 'utils';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const AppContent = styled('div')`
    // padding: ${TEMPLATE.LAYOUT_CONTENT_GUTTER}px;
    padding: 12px 20px;
    margin-top: ${TEMPLATE.HEADER_HEIGHT}px;
    min-height: calc(100vh - ${TEMPLATE.CONTENT_HEIGHT_OFFSET}px);
    position: relative;
    background-color:rgb(241, 243, 246);
    overflow-y: auto;

    ${props => props.isNavTop ? `
        max-width: ${TEMPLATE.CONTENT_MAX_WIDTH}px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @media ${MEDIA_QUERIES.DESKTOP} { 
            margin-top: ${TEMPLATE.HEADER_HEIGHT + TEMPLATE.TOP_NAV_HEIGHT}px;
            min-height: calc(100vh - ${TEMPLATE.CONTENT_HEIGHT_OFFSET}px - ${TEMPLATE.TOP_NAV_HEIGHT}px);
        }
    ` : ''}

    @media ${MEDIA_QUERIES.MOBILE} { 
        padding: ${TEMPLATE.LAYOUT_CONTENT_GUTTER_SM}px;
    }
`

export const AppLayout = ({ navCollapsed, navType, direction, children }) => {

    const location = useLocation();

    const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
    const screens = utils.getBreakPoint(useBreakpoint());
    const isMobile = screens.length === 0 ? false : !screens.includes('lg')
    const isNavSide = navType === TEMPLATE.NAV_TYPE_SIDE
    const isNavTop = navType === TEMPLATE.NAV_TYPE_TOP
    const getLayoutGutter = () => {
        if (isNavTop || isMobile) {
            return 0
        }
        return navCollapsed ? TEMPLATE.SIDE_NAV_COLLAPSED_WIDTH : TEMPLATE.SIDE_NAV_WIDTH
    }

    const getLayoutDirectionGutter = () => {
        if (direction === TEMPLATE.DIR_LTR) {
            return { paddingLeft: getLayoutGutter() }
        }
        if (direction === TEMPLATE.DIR_RTL) {
            return { paddingRight: getLayoutGutter() }
        }
        return { paddingLeft: getLayoutGutter() }
    }

    return (
        <Layout style={{height:"100%"}}>
            <HeaderNav isMobile={isMobile}/>
            {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo} /> : null}
            <Layout style={{height:"100%"}}>
                {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo} /> : null}
                <SideNav routeInfo={currentRouteInfo} />
                <Layout style={getLayoutDirectionGutter()} className='mainLayoutCallAI'>
                    <AppContent>
                        <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
                        <Content>
                            {/* <Suspense fallback={<Loading cover="content" />}> */}
                            <Suspense>
                                {children}
                            </Suspense>
                        </Content>
                    </AppContent>
                    {/* <Footer /> */}
                </Layout>
            </Layout>
            {isMobile && <MobileNav />}
        </Layout>
    )
}

const mapStateToProps = ({ theme }) => {
    const { navCollapsed, navType, locale } = theme;
    return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(AppLayout));