import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { canAccess } from 'services/AuthorizedService';


const hasAccess = canAccess();

let submenuOptions = hasAccess ? [
    {
        key: 'sidenav-callanalytics-mycalls',
        path: `${APP_PREFIX_PATH}/callanalytics/mycalls`,
        title: 'sidenav.callanalytics.mycalls',
        icon: '',
        breadcrumb: false,
        submenu: [],
    },
    {
        key: 'sidenav-callanalytics-teamcalls',
        path: `${APP_PREFIX_PATH}/callanalytics/teamcalls`,
        title: 'sidenav.callanalytics.teamcalls',
        icon: '',
        breadcrumb: false,
        submenu: []
    }] : [{
        key: 'sidenav-callanalytics-mycalls',
        path: `${APP_PREFIX_PATH}/callanalytics/mycalls`,
        title: 'sidenav.callanalytics.mycalls',
        icon: '',
        breadcrumb: false,
        submenu: [],
    }]


const dashBoardNavTree = [
    {
        key: 'sidenav-back-to-webapp',
        path: `https://web.callhippo.com/`,
        title: 'sidenav.back.to.webapp',
        breadcrumb: false,
        icon: ArrowLeftOutlined,
        submenu: [],
        className: 'back-to-webapp-menu-item',
    },
    ...(hasAccess) ? [{
        key: 'sidenav-dashboard',
        path: `${APP_PREFIX_PATH}/analytics`,
        title: 'sidenav.smart.dashboard',
        breadcrumb: false,
        icon: () => <span role="img" className="dashboardIcon anticon" />,
        submenu: [],
        className: 'dashboard-menu-item',
    }] : [],
    {
        key: 'sidenav-callanalytics',
        title: 'sidenav.callanalytics',
        icon: () => <span role="img" className="chartIcon anticon" />,
        breadcrumb: false,
        submenu: submenuOptions,
        className: 'callanalytics-menu-item'
    },
    {
        key: 'sidenav-playlist',
        path: `${APP_PREFIX_PATH}/playlist`,
        title: 'sidenav.playlist',
        breadcrumb: false,
        icon: () => <span role="img" className="myPlayListIcon anticon" />,
        submenu: [],
        className: 'myplaylist-menu-item',
    },
    ...(hasAccess) ? [{
        key: 'sidenav-customMoment',
        path: `${APP_PREFIX_PATH}/custom-moment`,
        title: 'sidenav.customMoments',
        breadcrumb: false,
        icon: () => <span role="img" className="folderIcon anticon" />,
        submenu: [],
        className: 'myplaylist-menu-item',
    }] : []
]


const navigationConfig = [...dashBoardNavTree]

export default navigationConfig;
