import styled from '@emotion/styled';
import { TEMPLATE } from 'constants/ThemeConstant';

const Header = styled.div(({headerNavColor, isDarkTheme}) => ({
	position: 'fixed',
	width: '100%',
	left: 0,
	zIndex: 1000,
	display: 'flex',
	flex: '0 0 auto',
	height: TEMPLATE.HEADER_HEIGHT,
	lineHeight: `${TEMPLATE.HEADER_HEIGHT}px`,
	backgroundColor: headerNavColor,
	// boxShadow: isDarkTheme ? '0 1px 8px -1px rgb(0 0 0 / 75%)' : '0 1px 4px -1px rgb(0 0 0 / 15%)'
	boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 2px 0px, rgba(0, 0, 0, 0.2) 0px 1px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 4px 0px'
}))


export default Header